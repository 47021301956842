import * as Yup from "yup"
import moment from "moment-timezone"

import { GATSBY_CLIENT_EMAIL_DOMAIN } from "gatsby-env-variables"

// const yearNow = moment().subtract(18, "years").year

const REQUIRED_MESSAGE = "This field is required"
const VALID_MOBILE_NUMBER_MESSAGE =
  "Please input a valid mobile number in this format: 09991234567"
const VALID_YEAR_MESSAGE = "Please input a valid year"

export const enrollmentValidationSchema = (isPersonal) => {
  let validationSchema = {
    employee: Yup.object().shape({
      firstName: Yup.string().required(REQUIRED_MESSAGE),
      lastName: Yup.string().required(REQUIRED_MESSAGE),
      birthday: Yup.object({
        date: Yup.object().shape({
          label: Yup.string().required(REQUIRED_MESSAGE),
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
        month: Yup.object().shape({
          label: Yup.string().required(REQUIRED_MESSAGE),
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
        year: Yup.number()
          .min(1900, VALID_YEAR_MESSAGE)
          .required(REQUIRED_MESSAGE),
      }),
      gender: Yup.string().required(REQUIRED_MESSAGE),
      mobileNumber: Yup.string()
        .min(11, VALID_MOBILE_NUMBER_MESSAGE)
        .max(11, VALID_MOBILE_NUMBER_MESSAGE)
        .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER_MESSAGE)
        .required(REQUIRED_MESSAGE),
      employeeNumber: Yup.string().required(REQUIRED_MESSAGE),
      site: Yup.object().shape({
        label: Yup.string().required(REQUIRED_MESSAGE),
        value: Yup.string().required(REQUIRED_MESSAGE),
      }),
      department: Yup.string().required(REQUIRED_MESSAGE),
      address: Yup.object().shape({
        // addressType: Yup.string().required(REQUIRED_MESSAGE),
        streetAddress: Yup.string().required(REQUIRED_MESSAGE),
        city: Yup.object().shape({
          label: Yup.string().required(REQUIRED_MESSAGE),
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
        province: Yup.object().shape({
          label: Yup.string().required(REQUIRED_MESSAGE),
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
      }),
    }),
    emergencyContact: Yup.object().shape({
      name: Yup.string().required(REQUIRED_MESSAGE),
      mobileNumber: Yup.string()
        .min(11, VALID_MOBILE_NUMBER_MESSAGE)
        .max(11, VALID_MOBILE_NUMBER_MESSAGE)
        .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER_MESSAGE)
        .required(REQUIRED_MESSAGE),
      relationship: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }

  if (isPersonal) {
    validationSchema.id = Yup.object().shape({
      front: Yup.object().shape({
        name: Yup.string().required(REQUIRED_MESSAGE),
      }),
      back: Yup.object().shape({
        name: Yup.string().required(REQUIRED_MESSAGE),
      }),
      // front: Yup.string().required(REQUIRED_MESSAGE),
      // back: Yup.string().required(REQUIRED_MESSAGE),
    })
  }

  return Yup.object().shape(validationSchema)
}

export const historyValidationSchema = Yup.object().shape({
  hasConcern: Yup.string().required(REQUIRED_MESSAGE),
  raisedConcern: Yup.string().when("hasConcern", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  causeDistress: Yup.string().required(REQUIRED_MESSAGE),
  seenMHP: Yup.string().required(REQUIRED_MESSAGE),
  seenMedGrocerMHP: Yup.string().required(REQUIRED_MESSAGE),
  medGrocerMHP: Yup.string().when("seenMedGrocerMHP", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hospitalizedForPsychiatricReasons: Yup.string().required(REQUIRED_MESSAGE),
  hasPsychiatricMeds: Yup.string().required(REQUIRED_MESSAGE),
  psychiatricMeds: Yup.string().when("hasPsychiatricMeds", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hasNonPsychiatricMeds: Yup.string().required(REQUIRED_MESSAGE),
  nonPsychiatricMeds: Yup.string().when("hasNonPsychiatricMeds", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  hasDrugAllergies: Yup.string().required(REQUIRED_MESSAGE),
  drugAllergies: Yup.string().when("hasDrugAllergies", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  beenDiagnosed: Yup.string().required(REQUIRED_MESSAGE),
  pastDiagnoses: Yup.string().when("beenDiagnosed", {
    is: "Yes",
    then: Yup.string().required(
      'If you feel uncomfortable answering this, please choose "I\'d rather not say" instead'
    ),
  }),
  kesslerNervous: Yup.string().required(REQUIRED_MESSAGE),
  kesslerHopeless: Yup.string().required(REQUIRED_MESSAGE),
  kesslerRestlessFidgety: Yup.string().required(REQUIRED_MESSAGE),
  kesslerDepressed: Yup.string().required(REQUIRED_MESSAGE),
  kesslerEffort: Yup.string().required(REQUIRED_MESSAGE),
  kesslerWorthless: Yup.string().required(REQUIRED_MESSAGE),
  wishedToBeDead: Yup.string().required(REQUIRED_MESSAGE),
})

export const cssrsValidationSchema = Yup.object().shape({
  currentSuicidalThoughts: Yup.string().required(REQUIRED_MESSAGE),
  suicidalThoughtsWithMethod: Yup.string().required(REQUIRED_MESSAGE),
  suicidalIntentWithoutSpecificPlan: Yup.string().required(REQUIRED_MESSAGE),
  suicidalIntentWithPlan: Yup.string().required(REQUIRED_MESSAGE),
  suicidalBehavior: Yup.string().required(REQUIRED_MESSAGE),
})

export const kesslerValidationSchema = Yup.object().shape({
  nervous: Yup.string().required(REQUIRED_MESSAGE),
  hopeless: Yup.string().required(REQUIRED_MESSAGE),
  restlessFidgety: Yup.string().required(REQUIRED_MESSAGE),
  depressed: Yup.string().required(REQUIRED_MESSAGE),
  effort: Yup.string().required(REQUIRED_MESSAGE),
  worthless: Yup.string().required(REQUIRED_MESSAGE),
})

export const scheduleValidationSchema = Yup.object().shape({
  schedule1: Yup.object().shape({
    day: Yup.string().required(REQUIRED_MESSAGE),
    time: Yup.string().required(
      "Please indicate when you are free at this chosen day"
    ),
  }),
  schedule2: Yup.object().shape({
    day: Yup.string().required(REQUIRED_MESSAGE),
    time: Yup.string().required(
      "Please indicate when you are free at this chosen day"
    ),
  }),
  schedule3: Yup.object().shape({
    day: Yup.string().required(REQUIRED_MESSAGE),
    time: Yup.string().required(
      "Please indicate when you are free at this chosen day"
    ),
  }),
  usedGoogleMeet: Yup.string().required(REQUIRED_MESSAGE),
})

export const genderChoices = ["Female", "Male", "Other"]

// export const currentRoleChoices = [
//   "Yogi or Life Coach",
//   "Master Avatar",
//   "Human Resources",
//   "Team Leader/Manager",
// ]

export const notifyIfEmployeeRequestsTeleconsultChoices = ["Yes"]

export const riskAssessmentScoreChoices = [
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "Moderate",
    value: "Moderate",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Emergency",
    value: "Emergency",
  },
]

export const sites = [
  { value: "alorica_alphaland", label: "Alphaland" },
  { value: "alorica_angeles", label: "Angeles" },
  { value: "alorica_by_the_bay", label: "By the Bay" },
  { value: "alorica_cebu", label: "Cebu" },
  { value: "alorica_centris", label: "Centris" },
  { value: "alorica_clarkfield", label: "Clarkfield" },
  { value: "alorica_cubao", label: "Cubao" },
  { value: "alorica_davao", label: "Davao" },
  { value: "alorica_davao_2", label: "Davao 2" },
  { value: "alorica_fort_bonifacio", label: "Fort Bonifacio" },
  { value: "alorica_lipa", label: "Lipa" },
  { value: "alorica_madrigal", label: "Madrigal" },
  { value: "alorica_makati", label: "Makati" },
  { value: "alorica_marikina", label: "Marikina" },
  { value: "alorica_northgate", label: "Northgate" },
  { value: "alorica_san_nicolas", label: "San Nicolas" },
  { value: "alorica_santa_mesa", label: "Sta. Mesa" },
]

export const daysOfTheWeek = [
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
  {
    label: "Sun",
    value: "Sun",
  },
]

export const timeBlocks = [
  {
    label: "12MN-3AM",
    value: "12MN-3AM",
  },
  {
    label: "3AM-6AM",
    value: "3AM-6AM",
  },
  {
    label: "6AM-9AM",
    value: "6AM-9AM",
  },
  {
    label: "9AM-12NN",
    value: "9AM-12NN",
  },
  {
    label: "12NN-3PM",
    value: "12NN-3PM",
  },
  {
    label: "3PM-6PM",
    value: "3PM-6PM",
  },
  {
    label: "6PM-9PM",
    value: "6PM-9PM",
  },
  {
    label: "9PM-12MN",
    value: "9PM-12MN",
  },
]

export const devSelfRequestInitialValues = {
  agreeToConsent: ["I agree to the terms and conditions and privacy policy"],
  employee: {
    firstName: "Juan",
    lastName: "dela Cruz",
    gender: "Male",
    birthday: {
      month: { label: "Jan", value: "Jan" },
      date: { label: 1, value: 1 },
      year: 2020,
    },
    site: sites[0],
    department: "IT",
    mobileNumber: "09991234567",
    personalEmail: "mcborreo@gmail.com",
    workEmail: "cborreo",
    employeeNumber: "12345",
  },
  emergencyContact: {
    fullName: "Maria dela Cruz",
    mobileNumber: "09992234567",
    relationshipToPatient: "Mother",
  },
  hasConcern: "I’m not sure",
  raisedConcern: "",
  currentConcern: "Been feeling anxious the past week",
  causeDistress: "Work",
  wellbeing: "3",
  seenMHP: "No",
  seenMedGrocerMHP: "No",
  medGrocerMHP: "",
  hospitalizedForPsychiatricReasons: "No",
  hasPsychiatricMeds: "Yes",
  psychiatricMeds: "Quetiapine",
  hasNonPsychiatricMeds: "Yes",
  nonPsychiatricMeds: "Losartan",
  hasDrugAllergies: "Yes",
  drugAllergies: "Ibuprofen",
  beenDiagnosed: "I’d rather not say",
  pastDiagnoses: "",
  notes: "Notes here",
  schedule: {
    month: {
      value: moment().format("MMM"),
      label: moment().format("MMM"),
    },
    date: "",
    year: 2020,
    timestamp: "",
  },
  usedGoogleMeet: ["Google Meet"],
}

export const devReferInitialValues = {
  agreeToConsent: [],
  referrer: {
    firstName: "Jose",
    lastName: "Santos",
    mobileNumber: "09997891234",
    emailAddress: "jsantos@medgrocer.com",
    currentRole: "Human Resources",
  },
  employee: {
    firstName: "Juan",
    lastName: "dela Cruz",
    emailAddress: "jdelacruz@mail.com",
    mobileNumber: "09991234567",
    site: {
      label: "FEX (Bulacan)",
      value: "FEX (Bulacan)",
    },
    department: "IT",
  },
  reasonForReferral: "Put reason for referral here",
  riskAssessmentScore: {
    label: "Low",
    value: "Low",
  },
}
