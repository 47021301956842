import moment from "moment"
export const dateConverter = (birthday) => {
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  }

  const { month, date, year } = birthday

  try {
    console.log(
      moment(`${year}-${month.label}-${date.label}`).format("YYYY-MMM-DD")
    )
    return moment(`${year}-${month.label}-${date.label}`).format("YYYY-MM-DD")
  } catch (error) {
    console.log({ error })
  }
  // return `${year}-${months[month.label]}-${date.label}`;
}
